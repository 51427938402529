import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({
  country: Yup.string()
    .min(2, 'Country must be 2 characters minimum')
    .required('Country is required'),     
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  consentsubscribe: Yup.boolean().oneOf([true], 'Terms and conditions need to be accepted'),
  consentstore: Yup.boolean().oneOf([true], 'Terms and conditions need to be accepted'),
  })

const formUrl = 'https://script.google.com/macros/s/AKfycbz_G-fsc11JMc_MNqHngxmKYenLIK1pU5ftmiKjn5z8KJsjpJchNgrgGKFGXVIQAJTrKA/exec'

const messageConfirm = () => {
  return toast.info("Thank you for contacting us.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class eNewsletter extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null,
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true,
        pageUrl: location.href 
      })
    })
  }

  handleChange = value => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <section className="mt-n5">
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol lg="9" md="12" className="md-0 mb-5">
            <Formik
              initialValues={{
                firstname: '',
                lastname: '',
                country: '',
                email: '',
                consentsubscribe: false,
                consentstore: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  Firstname: values.firstname,
                  Lastname: values.lastname,
                  Country: values.country,                  
                  Email: values.email,
                  Consentsubscribe: values.consentsubscribe,
                  Consentstore: values.consentstore,
                }
                try {
                  const response = axios({
                      method: 'get',
                      mode: 'no-cors',
                      url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&firstname=${encodeURIComponent(values.firstname)}&surname=${encodeURIComponent(values.lastname)}&country=${encodeURIComponent(values.country)}&email=${encodeURIComponent(values.email)}&subscribe=${encodeURIComponent(values.consentsubscribe)}&store=${encodeURIComponent(values.consentstore)}`
                    })
                      this.setState({values: '', expired: 'true'})
                      resetForm()
                      messageConfirm()
                    } catch (e) {
                      console.log(`Axios request failed: ${e}`)
                    }
                  setSubmitting(false)
                }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />

                  <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">eNewsletter subscription</h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  <MDBRow>
                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="firstname">
                          First name
                        </label>
                        <Field
                          type="firstname"
                          name="firstname"
                          aria-label="firstname"
                          value={values.firstname}
                          placeholder="Your first name"
                          className={`form-control ${ touched.firstname && errors.firstname ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="firstname"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>

                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="lastname">
                            Last name
                        </label>
                        <Field
                          type="lastname"
                          name="lastname"
                          aria-label="lastname"
                          value={values.lastname}
                          placeholder="Your last name"
                          className={`form-control ${ touched.lastname && errors.lastname ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="lastname"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>                    
                  </MDBRow>

                  <MDBRow>
                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="country">
                          Country <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="country"
                          aria-label="country"
                          value={values.country}
                          placeholder="Your country"
                          className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="country"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="email">
                          Email <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="email"
                          name="email"
                          aria-label="email"
                          value={values.email}
                          placeholder="Your email address"
                          className={`form-control ${ touched.email && errors.email ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  
                  </section>

<hr /> 

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        You hereby consent to iET Solutions processing your email address provided in the form to inform you about iET Solutions products, services, news and events within the scope of a newsletter. The legal basis for the processing is Art. 6 para. 1 lit a) GDPR.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="consentsubscribe"
                          name="consentsubscribe"
                          aria-label="consentsubscribe"
                          className={`form-check-input ${ touched.consentsubscribe && errors.consentsubscribe ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="consentsubscribe">
                          Yes, I agree. <span style={{ color: 'red' }}>*</span>
                        </label>
                        <ErrorMessage component="div" name="consentsubscribe"  className="invalid-feedback font-w-700 text-medium-small" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        You hereby consent to iET Solutions storing your data provided in this form on its servers and IT systems and processing it to respond to your request to receive the newletter. The legal basis for the processing is Art. 6 para. 1 lit. a) GDPR.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                  <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="consentstore"
                          name="consentstore"
                          aria-label="consentstore"
                          className={`form-check-input ${ touched.consentstore && errors.consentstore ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="consentstore">
                          Yes, I agree. <span style={{ color: 'red' }}>*</span>
                        </label>
                        <ErrorMessage component="div" name="consentstore" className="invalid-feedback font-w-700 text-medium-small" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                      Your consent given here is voluntary and can be revoked at any time and free of charge for the future. For more information on the storage and use of your personal data as well as your detailed rights and revocation options, please refer to our <Link to="/privacy-statement/" className="effect">privacy policy</Link>.
                      </div>
                    </MDBCol>
                  </MDBRow>               

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '120px' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>          
        </MDBRow>
      </MDBContainer>
      </section>
    )
  }
}

export default eNewsletter
